
import apiServices from "config/apiServices";
import * as _ from "lodash";
import moment from "moment";
import { useMemo } from "react";
import ReactBarcode from "react-jsbarcode";
import { useSelector } from "react-redux";
import { checkIsNameLeTanThuNgan, converGiaTriNguyCo, converGiaTriThamChieu, converKetQuaXnMayV2, getFullDiaChi, getResult, getStyles, locdau } from "../../../helpers";
import i18n, { languageKeys, languages } from "../../../i18n";
import Images from "components/Images/Images";
import { OnCareQR } from "assets/img";
import { enDateFormat } from "constants/keys";
import { renderMay } from "./KetQuaXn";

export const stylePhieuTraKqXn5s = `
#phieu-xn * {
  font-size: 13px;
  line-height: 1.6em;
  font-family: "Times New Roman", Times, serif;
}
#phieu-xn .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-xn .header {
  display: flex;
  position: relative;
  gap: 20px;
    font-size: 11px;
}
#phieu-xn .flex {
  display: flex;
  width:100%;
}
#phieu-xn .flex.justify {
  justify-content: space-around;
}

#phieu-xn .tel {
  margin-right: 50px;
}
#phieu-xn .txt-700 {
  font-weight: 700;
}

#phieu-xn .txt-400 {
  font-weight: 400 !important;
}
#phieu-xn .container-title {
  // font-weight: 700;
  font-size: 15px !important;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
#phieu-xn .container__title--main {
  // font-weight: 700;
  font-size: 13px;
  line-height: 14px;
}
#phieu-xn .container__ttbn--wrapper div {
  margin: 4px 0 0 0;
}
#phieu-xn .container-wrapper-flex {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#phieu-xn .container-wrapper-flex .col-3 {
  width: 30%;
}

#phieu-xn .container-wrapper-flex .col-4 {
  width: 40%;
}

#phieu-xn .thongtin-khambenh {
  width: 100%;
  display: flex;
  margin-top: 12px;
}

#phieu-xn .thongtin-khambenh .left {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#phieu-xn .flex .flex-35 {
  width: 35%;
}
#phieu-xn .flex .flex-40 {
  width: 40%;
}
#phieu-xn .flex .flex-50 {
  width: 50%;
}
#phieu-xn .table {
  margin-block: 1rem;
  width: 100%;
  border-collapse: collapse;
}

#phieu-xn .table * {
  font-size: 13px;
}

#phieu-xn .table,
.table th,
.table td {
  border: 1px solid black;
}

#phieu-xn .table th,
.table td {
  padding: 5px 10px;
}

#phieu-xn hr.solid {
  border-top: 1px solid #000;
}

#phieu-xn .footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}

#phieu-xn .footer .footer-confirm {
  margin-right: 44px;
  text-align: center;
}

#phieu-xn .name-space {
  height: 80px;
}

#phieu-xn .upperTxt {
  text-transform: uppercase;
}
#phieu-xn .txt-size-m {
  font-size: 13px;
}

#phieu-xn .footer .signature {
  width: 100px;
  height: 20px;
}

#phieu-xn .barcode {
  position: absolute;
  right: 0px;
  top: 0px;
  
  width: fit-content;
  height:60px;
}

#phieu-xn .barcode2 {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

#phieu-xn .list {
  margin:0;
  padding:0 0 0 12px;
  list-style-type:none;
}
#phieu-xn .phone-website { display: flex; gap: 24px; align-items: center }

.report-content {
  margin-top: 20px; 
}

td, tr, td {
  padding: 5px 10px !important;
  margin: 0px  !important;
}

 td p {
  padding: 0px !important;
  margin: 0px  !important;
}



@media print {
  #phieu-xn .foot-break {
    break-inside: avoid;
  }
  #phieu-xn{page-break-after: always}
}
`;

const PhieuTraKqXn5s = ({ data }) => {
  const userProfile = useSelector((state) => state.auth.user);
  const { BENH_VIEN: benh_vien, data: arrXn, BENH_PHAM: benh_pham } = data;

  const convertGioiTinh = (sex) => {
    switch (sex) {
      case "nam":
        return i18n.t(languageKeys.gioi_tinh_nam);
      case "nu":
        return i18n.t(languageKeys.gioi_tinh_nu);
      case "khac":
        return i18n.t(languageKeys.gioi_tinh_khac);
      default:
        if (typeof sex === "string") {
          return sex.toUpperCase();
        }
        return sex;
    }
  };


  const getFontWeight = (KQ, record, benh_pham) => {
    if (record.KIEU_CHI_SO === "MOT_LUA_CHON" || record.KIEU_CHI_SO === "NHIEU_LUA_CHON") {
      return getStyles(record.KET_QUA_XN || record?.KET_QUA_XN_MAY, record, benh_pham)?.trim()?.length > 0
        ? locdau(getResult(record.KET_QUA_XN || record?.KET_QUA_XN_MAY)?.trim()?.toLowerCase())?.includes(
          "duong tinh",
        )
          ? "bold"
          : ""
        : ""
    }

    return getStyles(record.KET_QUA_XN || record?.KET_QUA_XN_MAY, record, benh_pham, "fontWeight")
  }

  const getStyle = (subItem, color) => {
    return {
      color: getStyles(subItem.KET_QUA_XN || subItem?.KET_QUA_XN_MAY, subItem, benh_pham, null, color),
      fontWeight: getStyles(subItem.KET_QUA_XN || subItem?.KET_QUA_XN_MAY, subItem, benh_pham, "fontWeight"),
      textAlign: "center",
      textDecoration: getStyles(subItem.KET_QUA_XN || subItem?.KET_QUA_XN_MAY, subItem, benh_pham, "textDecoration"),
      fontSize: getStyles(subItem.KET_QUA_XN || subItem?.KET_QUA_XN_MAY, subItem, benh_pham, "size"),
    };
  };


  const Barcode = useMemo(() => {

    if (!!benh_pham.BARCODE_XET_NGHIEM) {
      return <p style={{ display: "flex", flexDirection: "column", position: "relative", bottom: "-13px" }}><ReactBarcode value={benh_pham.BARCODE_XET_NGHIEM} options={{
        format: 'code128', fontSize: 13, width: 1, height: userProfile?.config?.ISHEADER == 0 ? 25 : 50,
        textMargin: 0,
        margin: 0,
        padding: 0,
        displayValue: false
      }} renderer="image" /><span>{benh_pham.BARCODE_XET_NGHIEM}</span>
      </p>;
    }

    return ""

  }, [benh_pham?.BARCODE_XET_NGHIEM])

  let totalIndex = 0;
  return (
    <div id="phieu-xn">
      <table class="report-container" style={{ width: "100%" }}>
        <thead class="report-header" style={{ width: "100%" }}>
          <tr style={{ width: "100%" }}>
            <th class="report-header-cell" style={{ width: "100%" }}>
              <div class="header-info" style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  {userProfile?.config?.ISHEADER == 0 ? (
                    <div className="header-2" style={{ height: "75px" }}></div>
                  ) : (
                    <>
                      <div className="header">
                        {
                          !!userProfile?.benh_vien?.ANH_DAI_DIEN ? (
                            <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>
                          ) : (
                            <></>
                          )
                        }
                        <div>
                          <div style={{ fontWeight: "bold", textTransform: "uppercase", textAlign: "left" }}>{benh_vien.TEN_CSKCB}</div>
                          <div style={{ textAlign: "left", maxWidth: "calc(100vw - 230px)" }}>{i18n.t(languageKeys.dia_chi)}: {getFullDiaChi(benh_vien)}</div>
                          <div className="phone-website">
                            <div>
                              <span >{i18n.t(languageKeys.so_dien_thoai)}: </span>
                              {formatPhoneNumber(benh_vien.SO_DIEN_THOAI)}
                            </div>
                            <div style={{ display: "none" }}>
                              <span className="">Website: {benh_vien?.WEBSITE}</span>
                            </div>
                          </div>
                          <div style={{ textAlign: "left", display: "none" }}>Email: {benh_vien?.EMAIL}</div>
                        </div>
                        <div className="barcode">
                          {Barcode}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="container-title" style={{ position: "relative" }}>
                    <span style={{ fontSize: 19 }}>{i18n.t(languageKeys.phieu_ket_qua_xet_nghiem)}</span>
                    <div className="barcode2">
                      {userProfile?.config?.ISHEADER == 0 && Barcode}
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: "40% 30% 30%" }}>
                      <div style={{ display: "flex", gap: "20%", textAlign: "left" }}>
                        <div className="txt-400" style={{ textAlign: "left" }}>
                          {i18n.t(languageKeys.ho_va_ten)}:{" "}
                          <span style={{ fontWeight: "" }} className="txt-700">
                            {benh_pham.TEN}
                          </span>
                        </div>
                      </div>
                      <div className="txt-400" style={{ display: "flex", gap: "20%", textAlign: "left" }} >
                        <div className="txt-400" style={{ textAlign: "left" }}>
                          {i18n.t(languageKeys.ngay_sinh)}:
                          {benh_pham.NGAY_SINH?.toString()?.length === 4
                            ? ` ${benh_pham.NGAY_SINH}`
                            : moment(benh_pham.NGAY_SINH).format(" DD / MM / YYYY")}
                        </div>

                      </div>
                      <div className="txt-400" style={{ textAlign: "left" }} >
                        {i18n.t(languageKeys.gioi_tinh)}: {convertGioiTinh(benh_pham.GIOI_TINH)}
                      </div>
                    </div>

                    <div className="txt-400" style={{ display: "grid", gridTemplateColumns: "40% 30% 30%" }}>
                      <div style={{ textAlign: "left" }}>
                        {i18n.t(languageKeys.so_dien_thoai)}:{" "}
                        <span className="" style={{ fontWeight: "" }}>
                          {formatPhoneNumber(benh_pham.SDT)}
                        </span>
                      </div>
                      <div style={{ textAlign: "left" }}>
                        {i18n.t(languageKeys.field_Doi_tuong)}: {benh_pham.TEN_DOI_TUONG}
                      </div>
                      <div style={{ textAlign: "left" }}>
                        {userProfile?.config?.IS_LISENCE && (`${i18n.t(languageKeys.driving_license)}: ${benh_pham?.BANG_LAI_XE || ""}`)}
                      </div>
                    </div>

                    <div className="txt-400" style={{ textAlign: "left" }}>
                      {i18n.t(languageKeys.dia_chi)}: {getFullDiaChi(benh_pham)}
                    </div>

                    <div className="txt-400" style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
                      <div style={{ textAlign: "left" }}>
                        {/* {i18n.t(languageKeys.nguoi_chi_dinh)}: {convertListName(benh_pham.TEN_NGUOI_TAO_PHIEU)} */}
                        {i18n.t(languageKeys.common_ngay_kham)}: {benh_pham?.NGAY_KHAM ? moment(benh_pham.NGAY_KHAM).format(" DD / MM / YYYY") : ""}
                      </div >
                      <div style={{ textAlign: "left" }}>
                        {/* {i18n.t(languageKeys.chan_doan)}: {benh_pham.CHAN_DOAN} */}
                        {i18n.t(languageKeys.nguoi_thuc_hien)}: {benh_pham.NGUOI_TAO}
                      </div>
                    </div>

                    <div className="txt-400" style={{ textAlign: "left" }}>
                      {/* {i18n.t(languageKeys.nguoi_thuc_hien)}: {benh_pham.NGUOI_TAO} */}
                    </div>

                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="report-content">
          <tr>
            <td class="report-content-cell">
              <div class="main" >

                <div>
                  <table className="table">
                    <thead>
                      <th style={{width:"5%"}}  className="" >{i18n.t(languageKeys.stt)}</th>
                      <th style={{width:"40%"}}  colSpan="2" className="" /*style={{ textAlign: "left" }}*/>
                        {i18n.t(languageKeys.xet_nghiem)}
                      </th>
                      <th style={{width:"15%"}}  className="">{i18n.t(languageKeys.field_Ket_qua)}</th>
                      <th style={{width:"10%"}}  className="">{i18n.t(languageKeys.field_Don_vi)}</th>
                      <th style={{width:"20%"}}  className="">{i18n.t(languageKeys.gia_tri_tham_chieu)}</th>
                      <th style={{width:"10%"}}  className="">{i18n.t(languageKeys.mo_ta)}</th>
                    </thead>
                    <tbody>
                      {Array.isArray(arrXn) &&
                        arrXn.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td colSpan="7" style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                                  {item.TEN_LOAI_DV}
                                </td>
                              </tr>
                              {Array.isArray(item?.data) &&
                                item.data.map((subItem, subIndex) => {
                                  if (subItem?.PDT_TYPE !== "PDT_CON_ID") {
                                    totalIndex++;
                                  }
                                  return (
                                    <tr>
                                      <td style={{ textAlign: "center" }} >{subItem?.PDT_TYPE !== "PDT_CON_ID" ? totalIndex : ""}</td>
                                      {/* <td></td> */}
                                      {subItem?.PDT_TYPE !== "PDT_CON_ID" ? (
                                        <td colSpan={2}>{subItem.TEN_DV}</td>
                                      ) : (
                                        <td colSpan={2} >&emsp; {subItem.TEN_DV}</td>
                                      )}
                                      {/* <td style={getStyle(subItem)}>{getResult(subItem.KET_QUA_XN ||subItem.KET_QUA_XN_MAY)}</td> */}
                                      <td style={getStyle(subItem, "colorless")}><p  dangerouslySetInnerHTML={{ __html: getResult(subItem.KET_QUA_XN || subItem?.KET_QUA_XN_MAY, subItem, benh_pham, true)}}></p></td>
                                      <td style={{ textAlign: "center" }}>
                                        {/* {subItem.TEN_DVT ? `${subItem.TEN_DVT}` : ""} */}
                                        {(subItem.TEN_DVT || subItem?.DVT_MAY) ? (renderMay(subItem,"TEN_DVT")) : ""}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                      {renderMay(subItem,"THAM_CHIEU", benh_pham, userProfile.partner_code)}
                                      </td>

                                      <td>{subItem.GHI_CHU || subItem.GHI_CHU_MAY}</td>
                                    </tr>
                                  );
                                })}
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <div className="container-footer">
                  <div style={{ textAlign: "right" }}>
                    {i18n.language === languages.tieng_viet ? (
                      <i>
                        {moment().format("HH:mm") + ", "}
                        Ngày {moment().date()} tháng {moment().month() + 1} năm {moment().year()}
                      </i>
                    ) : (
                      <i> {moment().format(enDateFormat)}</i>
                    )}
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span className="txt-700" style={{ textTransform: "capitalize" }}>
                      {/* {`${i18n.t(languageKeys.xac_nhan_kq)}`} */}{" "}
                    </span>
                    <span className="txt-700" style={{ textTransform: "capitalize" }}>
                      {i18n.t(languageKeys.technical_and_specialized_gynecology).toUpperCase()}
                    </span>
                  </div>
                  <div className="signature"></div>

                  <div style={{ width: "100%", height: "100px", textAlign:"end" }}>
                    {
                      benh_pham?.HIEN_THI_CHU_KY ? (
                        <Images imgId={benh_pham?.CHU_KY_DIEN_TU} fromService={'FILE'} style={{ maxHeight:70, maxWidth: 150, objectFit:"cover" }} />
                      ) : (<></>)
                    }
                  </div>
                  <p>{i18n.t(languageKeys.mo_ta)}: </p>
                  <p>1. {i18n.t(languageKeys.note_print_xn)}</p>
                  <p>2. {i18n.t(languageKeys.note_print_xn_2)}</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {!!userProfile.config?.QR_DOWNLOAD_APP && (
        <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 10 }}>
          <img src={OnCareQR} alt="QR" style={{ height: 85, width: 85 }} />
          <i style={{ fontWeight: 700 }}>
            {i18n.t(languageKeys.phieukhambenh_emp_qr_oncare)}
          </i>
        </div>
      )}

    </div>
  );
};

export default PhieuTraKqXn5s;

export const formatPhoneNumber = (phoneNumber = "") => {
  if (!_.isString(phoneNumber)) return phoneNumber;

  if (phoneNumber.length === 8) {
    return phoneNumber.replace(/\D*(\d{4})\D*(\d{4})\D*/, "$1 $2");
  }

  return phoneNumber.replace(/\D*(\d{4})\D*(\d{3})\D*(\d{3})\D*/, "$1 $2 $3");
};

export const convertListName = (string, name = i18n.t(languageKeys.tiep_tan)) => {
  if (checkIsNameLeTanThuNgan(string)) {
    return ""
  }
  let _string = string;
  let result = "";

  if (!string.includes(";") && string.length === 0) return name;
  if (!string.includes(";") && !!string.length) return string;

  if (_string === "") {
    return result;
  }

  if (string.includes(";")) {
    _string = _string.split(";");
  }
  if (!!_string.length && _string.length < 2) {
    result = !_string[0] ? `${name}` : _string[0].join("");
  } else if (!!_string.length && _string.length >= 2) {
    for (let i = 0; i < _string.length; i++) {
      const element = _string[i];
      if (element.length == 0) {
        _string[i] = name;
      }
    }
    result = _string.join(";");
  }
  return result;
};
