import React, { useEffect } from "react";
import { FormDrawer, ModalPDF, Table } from "components";
import i18n, { languageKeys } from "../../i18n";
import { Layout, Button, Input, Row, Col, Checkbox, notification, Form, Select as SelectAntd, InputNumber } from "antd";
import keys, { indexedDbKeys, widthDrawerKeys } from "constants/keys";
import { useDispatch, useSelector } from "react-redux";
import { PackageSliceAction, PackageState } from "ducks/slices/Features/packageSlice";
import { HLog, common_post, formatCurrency2, getRoomWithServiceId, rid, validFragment } from "../../helpers";
import style from "./styles/style.module.less";
import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import * as _ from "lodash";
import { Select } from "components_v2";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { apis } from "../../constants";
import ModalActiveServicePack from "./ModalActiveServicePack";
import ModalDangKyNhanhGoi from "pages/QuanLyTiepDon/ModalTiepDon.new/components/ModalDangKyNhanhGoi";
import SiderIngredient from "./SiderAddPack";
import { useEventListener } from "hooks";

let suffix = "-";

/**
 * @name PackageData
 * @typedef {Object} PackageData
 * @property {string} key
 * @property {string} TEN_GOI_DV
 */

/**
 * @name ListPack
 * @typedef {Array<PackageData>} ListPack
 */

const ModalAddPackages = React.forwardRef(({ onAfterSubmit = () => {}, onSelectPatient = () => {} }, ref) => {
  const dispatch = useDispatch();
  const { customerTarget } = useSelector(getSharedData);
  const userProfile = useSelector((state) => state.auth.user);

  const [form] = Form.useForm();
  const [formBn] = Form.useForm();
  const activeServRef = React.useRef();
  const fastRef = React.useRef();
  const pdfRef = React.useRef();
  /**
   * @name patientPackageList
   * @type {{patient: Object<any>,dataSource: Array<any>}}
   */
  const { patientPackageList } = useSelector(PackageState);
  const forceRender = React.useState();

  const [open, setOpen] = React.useState(false);
  const [optionPackages, setOptionPackages] = React.useState([]);
  const [selectedService, setSelectedService] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [appointmentDetail, setAppointmentDetail] = React.useState();
  const [treeProps, setTreeProps] = React.useState({
    expandedKey: [],
    selectedKey: [],
    halfCheckedKeys: [],
    checked: [],
    disableKeys: [],
  });
  React.useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  const defaultColumns = [
    { title: i18n.t(languageKeys.field_Ten_dich_vu), key: "TEN_DV", dataIndex: "TEN_DV", width: 220 },
    {
      title: (
        <div>
          {i18n.t(languageKeys.field_Phong_thuc_hien)} <span className="red-txt">*</span>
        </div>
      ),
      key: "P_ID",
      dataIndex: "P_ID",
      width: 230,
      /**
       * @param {Array<string>} data Array PHONG_ID
       * @param {Object} record bản ghi
       */
      render: (data, record) => {
        if (!!record.DV_KEM_THEO) return null;
        return (
          <Form.Item rules={[{ required: true }]} initialValue={data} name={`P_ID_${record.key}`}>
            <RoomSelect
              key={"ROOM_KEY" + record.key}
              record={record}
              roomListId={record?.LIST_PHONG_ID || [record.P_ID]}
              roomId={data}
              setDataSource={setSelectedService}
            />
          </Form.Item>
        );
      },
    },
    {
      title: i18n.t(languageKeys.field_don_gia),
      key: "DON_GIA_PHAN_BO",
      dataIndex: "DON_GIA_PHAN_BO",
      render: (data) => formatCurrency2(data, "", true),
      width: 120,
    },
    // {
    //   title: i18n.t(languageKeys.sl),
    //   key: "SO_LUONG",
    //   dataIndex: "SO_LUONG",
    //   width: 100
    // },
    {
      title: i18n.t(languageKeys.sl),
      dataIndex: "SO_LUONG",
      width: 75,
      render: (data, record) => record && !record.DV_KEM_THEO && (
        <InputNumber
          value={data}
          style={{ width: 65 }}
          min={1}
          max={record.CON_LAI}
          size="middle"
          onChange={(value) => setSelectedService(
            selectedService.map(i => i.key === record.key
              ? { ...i, SO_LUONG: value, THANH_TIEN_PHAN_BO: value * i.DON_GIA_PHAN_BO }
              : i
            ))
          }
        />
      ),
    },
    {
      title: i18n.t(languageKeys.thanh_tien),
      key: "THANH_TIEN_PHAN_BO",
      dataIndex: "THANH_TIEN_PHAN_BO",
      render: (data) => formatCurrency2(data, "", true),
      width: 120,
    },
    {
      title: (
        <div>
          {i18n.t(languageKeys.cate_doi_tuong)} <span className="red-txt">*</span>
        </div>
      ),
      key: "DM_DOI_TUONG_ID",
      dataIndex: "DM_DOI_TUONG_ID",
      width: 200,
      render: (data, record) => {
        if (!!record.DV_KEM_THEO) return null;
        return <SelectChangeTarget targetId={data} record={record} setDataSource={setSelectedService} />;
      },
    },
    {
      title: i18n.t(languageKeys.field_Da_TT),
      align: "center",
      key: "THANH_TOAN",
      dataIndex: "THANH_TOAN",
      width: 70,
      render: (data) => {
        if (data) {
          return <Checkbox checked />;
        } else {
          return <Checkbox checked={false} />;
        }
      },
    },
    {
      title: (
        <div>
          {i18n.t(languageKeys.noi_thuc_hien)}
        </div>
      ),
      key: "P_ID",
      dataIndex: "NOI_THUC_HIEN_XN",
      width: 230,
      render: (data, record) => {
        if (!!record.DV_KEM_THEO || (!record?.LIST_NOI_THUC_HIEN_XN || record?.LIST_NOI_THUC_HIEN_XN?.length === 0)) return null;
        return (
          <Form.Item rules={[{ required: true }]} initialValue={data} name={`P_ID_${record.key}`}>
            <NoiThucHienXnSelect
              key={"ROOM_KEY" + record.key}
              record={record}
              roomListId={record?.LIST_PHONG_ID || [record.P_ID]}
              data={data}
              setDataSource={setSelectedService}
              noiThucHienList={record?.LIST_NOI_THUC_HIEN_XN}
              dataSource={selectedService}
              forceRender={forceRender}
            />
          </Form.Item>
        );
      },
    },
    {
      title: i18n.t(languageKeys.ghi_chu),
      key: "GHI_CHU",
      dataIndex: "GHI_CHU",
      width: 200,
      render: (data, record) => {
        if (record.PHAN_LOAI !== keys.COMBO_DV) {
          return (
            <Form.Item initialValue={data} name={`GHI_CHU${suffix}${record.key}`}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} style={{ width: "100%" }} />
            </Form.Item>
          );
        } else {
          return <></>
        }
      },
    },
  ];

  // xử lý dữ liệu key ...
  const formatPackageListData = async (data = []) => {
    try {
      let listKey = [
        "DS_DV",
        "GIA_GOI_DV",
        "GOI_DV_ID",
        "GOI_ID",
        "MA_GOI_DV",
        "NGAY_KICH_HOAT",
        "NGAY_SU_DUNG",
        "NGAY_TAO",
        "TEN_LOAI_GOI",
        "TEN_GOI_DV",
        "RLS_BN_GOI_ID",
        "DV_ID",
        "DM_DOI_TUONG_ID",
        "IS_ACTIVE",
        "IS_COMPLETE",
        "NGAY_SU_DUNG",
        "NGAY_SU_DUNG",
        "NGAY_KICH_HOAT",
        "NGAY_HET_HAN",
      ];

      let result = await validFragment(data, listKey);
      result = result.map((i) => {
        // danh sách gói
        return {
          ...i,
          key: i.GOI_DV_ID,
          // danh sách combo hoặc dịch vụ
          children: i.DS_DV.map((sub) => {
            const dichVu = {
              ...sub,
              GOI_DV_ID: i.GOI_DV_ID,
              key: `${sub.ID}${suffix}GOI${suffix}${i.GOI_DV_ID}`,
              is_combo: sub?.PHAN_LOAI === keys.COMBO_DV,
              IS_ACTIVE: i.IS_ACTIVE,
              DM_DOI_TUONG_ID: customerTarget?.[0]?.ID,
              children:
                sub.DV_KEM_THEO?.map((dv) => {

                  const dvKemTheo = {
                    ...dv,
                    GOI_DV_ID: i.GOI_DV_ID,
                    DM_DOI_TUONG_ID: customerTarget?.[0]?.ID,
                    IS_ACTIVE: i.IS_ACTIVE,
                    key: `${dv.ID}${suffix}GOI${suffix}${i.GOI_DV_ID}`,
                    keyParent: `${i.GOI_DV_ID}${suffix}${sub.RLS_BN_GOI_DV_ID}${sub?.PHAN_LOAI !== keys.COMBO_DV ? suffix + sub.DV_ID : ""}`,
                    isChildren: true
                  }

                  if (dvKemTheo.LIST_NOI_THUC_HIEN_XN && dvKemTheo.LIST_NOI_THUC_HIEN_XN.length > 0) {
                    const element = dvKemTheo?.LIST_NOI_THUC_HIEN_XN.find((obj) => obj?.IS_DEFAULT == 1);
                    dvKemTheo.NOI_THUC_HIEN_XN = element;
                  }

                  return dvKemTheo
                }) || [],
            }


            if (dichVu.LIST_NOI_THUC_HIEN_XN && dichVu.LIST_NOI_THUC_HIEN_XN.length > 0) {
              const element = dichVu?.LIST_NOI_THUC_HIEN_XN.find((obj) => obj?.IS_DEFAULT == 1);
              dichVu.NOI_THUC_HIEN_XN = element;
            }

            return dichVu;
          }),
        };
      });
      return result;
    } catch (error) {
      HLog(error);
      return [];
    }
  };

  /**
   * @description lấy chi tiết lịch khám
   * @param {string} LICH_KHAM_ID
   * @returns {any}
   */
  const handleFetchAppointmentDetail = async (LICH_KHAM_ID) => {
    let dataRequest = {
      partner_code: userProfile.partner_code,
      LICH_KHAM_ID,
    };
    setLoading(true);
    try {
      const rs = await common_post(apis.tiep_don_chi_tiet_lich_kham, dataRequest);
      if (rs.status === "OK") {
        setAppointmentDetail({ ...rs.result, LICH_KHAM_ID });
        return rs.result;
      } else {
        genNotificationError(i18n.t(languageKeys.lay_thong_tin_that_bai));
        throw new Error(rs.message);
      }
    } catch (error) {
      HLog(error);
      throw error.message;
    } finally {
      setLoading(false);
    }
  };

  /**
   * @name handleGetServiceHadServ
   * @param {{[key: string]: string, DS_DV_GOI: Array<DvGoi>}} data
   */
  const handleGetServiceHadServ = async (data) => {
    let { DS_DV_GOI } = data;
    try {
      let checkedKeys = DS_DV_GOI.map((i) => `${i.ID || i.ID_DV_IN_GOI}${suffix}GOI${suffix}${i.GOI_ID}`);
      setTreeProps((c) => {
        return {
          ...c,
          // checked: [...c.checked, ...checkedKeys],
          disableKeys: checkedKeys,
        };
      });
    } catch (error) {}
  };

  const formatAndSetOptions = async (data = []) => {
    data = await formatPackageListData(data);
    setOptionPackages(data);
  };
  /**
   * @description trigger khi đã có dữ liệu trong store
   */
  const handleOpen = async ({ LICH_KHAM_ID }) => {
    if (!LICH_KHAM_ID) return;
    try {
      setLoading(true);
      setOpen(true);
      new Promise((resolve, reject) => {
        let rs = handleFetchAppointmentDetail(LICH_KHAM_ID);
        resolve(rs);
      })
        .then(async (rs) => {
          if (patientPackageList?.dataSource) {
            await formatAndSetOptions(patientPackageList?.dataSource);
          } else {
            HLog("chưa có dữ liệu rồi !");
          }
          return rs;
        })
        .then((rs) => {
          handleGetServiceHadServ(rs);
        })
        .catch((err) => {
          HLog(err);
        });
    } catch (error) {
      HLog(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setTreeProps({
      expandedKey: [],
      selectedKey: [],
      halfCheckedKeys: [],
      checked: [],
    });
    setSelectedService([]);
    dispatch(PackageSliceAction.resetSlice());
    form.resetFields();
    formBn.resetFields();
  };

  const handleRegister = async (checkedNodes = []) => {
    try {
      let itemNotReg = checkedNodes.find((c) => !c["aria-active"]);
      if (!itemNotReg) return;
      let thisOption = optionPackages.find((op) => op?.GOI_DV_ID == itemNotReg?.key?.split(suffix)?.[0]);

      activeServRef.current.open(thisOption);
    } catch (error) {}
  };

  /**
   *
   * @param {Array<{key:string, title:string}>} checkedKeys
   * @returns {ListPack}
   */
  const handleSelectService = ({ checked, checkedNodes = [], halfCheckedKeys = [] }) => {
    try {
      if (checkedNodes.some((c) => !c["aria-active"])) {
        handleRegister(checkedNodes);
        return;
      }
      setTreeProps((c) => ({
        ...c,
        halfCheckedKeys,
        checked: checkedNodes?.map((i) => i?.key),
      }));
      //------------------------------------------------------------------------
      let arrKeys = checkedNodes
        .filter((i) => !i.disabled)
        .map((i) => {
          let keyItem = i.key.split(suffix);
          return keyItem;
          // lọc ra các option node là gói dịch vụ
        })
        .filter((i) => i.length !== 1);
      let allDv = _.flatten(optionPackages.map((i) => i.children));
      //------------------------------------------------------------------------
      const checkArrKeyOptions = [arrKeys.map((i) => i.slice(0, 2).join(suffix)), arrKeys.map((i) => i.join(suffix))];
      let newSelectedService = allDv
        .filter((dv) => checkArrKeyOptions.some((arr) => arr.includes(dv.key)))
        .map((i) => {
          let rs = {
            ...i,
            SO_LUONG: 1,
            THANH_TIEN_PHAN_BO: i.DON_GIA_PHAN_BO,
          };
          let children = i.children.filter((dv) => arrKeys.map((i) => i.join(suffix)).includes(dv.key));
          delete rs.children;
          rs.children = children.length ? children : undefined;
          return rs;
        });

      setSelectedService(newSelectedService);
      //------------------------------------------------------------------------
      return;
    } catch (error) {
      HLog(error);
    }
  };

  const genNotificationError = (message = "") => {
    notification.error({
      message,
      placement: "bottomLeft",
    });
  };

  /**
   *
   * @param {Array} arrServ
   */
  const formatterService = (arrServ) => {
    try {
      arrServ = arrServ.map((serv) => ({
        PHONG_THUC_HIEN_ID: serv.P_ID,
        DV_ID: serv.DV_ID,
        SO_LUONG: serv.SO_LUONG,
        KHOA_ID: serv.KHOA_ID,
        PHONG_CHI_DINH: i18n.t(languageKeys.phong_tiep_don),
        UU_TIEN: 0,
        DON_GIA: checkNumber(+serv.DON_GIA),
        THANH_TIEN: checkNumber(+serv.THANH_TIEN),
        DON_GIA_PHAN_BO: checkNumber(+serv.DON_GIA_PHAN_BO),
        THANH_TIEN_PHAN_BO: checkNumber(+serv.THANH_TIEN_PHAN_BO),
        ID: serv.ID,
        RLS_BN_GOI_DV_ID: serv.RLS_BN_GOI_DV_ID,
        PHAN_LOAI: serv.PHAN_LOAI,
        // DV_KEM_THEO: serv.DV_KEM_THEO,
        RLS_BN_GOI_ID: serv.RLS_BN_GOI_ID,
        BAC_SI_ID: serv?.BAC_SI_ID,
        RLS_BN_GOI_DV_DVCON_ID: serv.RLS_BN_GOI_DV_DVCON_ID,
        PDT_ID: serv.PDT_ID,
        GOI_ID: serv.GOI_ID,
        DM_DOI_TUONG_ID: serv.DM_DOI_TUONG_ID,
        GHI_CHU: serv.GHI_CHU,
        DV_XN_NOI_THUC_HIEN_ID: serv?.NOI_THUC_HIEN_XN?.DV_XN_NOI_THUC_HIEN_ID || undefined,
      }));

      return arrServ;
    } catch (error) {
      HLog(error);
      return arrServ;
    }
  };

  const handleSubmit = async (isReload = true) => {
    if (loading) return;
    setLoading(true);
    if (!Array.isArray(selectedService) || selectedService.length === 0) {
      genNotificationError(i18n.t(languageKeys.error_khong_co_dich_vu));
      throw i18n.t(languageKeys.error_khong_co_dich_vu);
    }
    if (!_.isObject(appointmentDetail)) {
      genNotificationError(i18n.t(languageKeys.error_loi_ket_noi));
      return;
    }

    let checkIsCombo = (dv) => {
      if (!!dv?.children && "DV_ID" in dv) {
        return true;
      } else {
        return false;
      }
    };
    try {
      let arrServiceSubmit = [];
      for (const i of selectedService) {
        if (checkIsCombo(i)) {
          arrServiceSubmit.push(...i.children);
        } else {
          arrServiceSubmit.push(i);
        }
      }
      let formData = await form.validateFields();

      try {
        arrServiceSubmit = arrServiceSubmit.map((ser) => {
          if (`GHI_CHU${suffix}${ser.key}` in formData) {
            return { ...ser, GHI_CHU: formData[`GHI_CHU${suffix}${ser.key}`] };
          } else {
            return ser;
          }
        });
      } catch (error) {
        HLog(error);
      }

      const apptBody = _.pick(appointmentDetail, Object.keys(appointmentColumnStandard));
      let body = {
        ...apptBody,
        MO_TA: formData.GHI_CHU,
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        NHANSU_ID: userProfile.NHANSU_ID,
        DS_DV_GOI: formatterService(arrServiceSubmit),
        BENH_NHAN_ID: appointmentDetail.ID,
      };
      let res = await common_post(apis.themdvGoiCuocKham, body);

      if (res && res.status === "OK") {
        notification.success({ message: i18n.t(languageKeys.thao_tac_thanh_cong), placement: "bottomLeft" });
        handleClose();
        onAfterSubmit();
        return res;
      } else {
        genNotificationError(res.error);
        throw res?.error;
      }
    } catch (error) {
      HLog(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleAfterActive = async (packetActive) => {
    try {
      const fillActive = (a) => {
        return { ...a, IS_ACTIVE: 1 };
      };
      /**
       *
       * @param {Object} selection obj
       * @param {string} key
       * @returns {Array<any>}
       */
      let changeActive = (selection = {}, key) => {
        return selection[key]?.map(fillActive);
      };
      let newOpts = optionPackages.map((opt) => {
        if (`${opt.GOI_DV_ID}` === `${packetActive.GOI_DV_ID}`) {
          return {
            ...opt,
            IS_ACTIVE: 1,
            DS_DV: opt.DS_DV?.length ? changeActive(opt, "DS_DV") : opt.DS_DV,
            children: opt.children?.length
              ? changeActive(opt, "children").map((c) => {
                return {
                  ...c,
                  children: c.children?.length ? changeActive(c, "children") : c.children,
                  DV_KEM_THEO: c.DV_KEM_THEO?.length ? changeActive(c, "DV_KEM_THEO") : c.DV_KEM_THEO,
                };
              })
              : opt.children,
          };
        }
        return opt;
      });
      setOptionPackages(newOpts);
      setTimeout(() => {
        notification.success({ message: i18n.t(languageKeys.thao_tac_thanh_cong), placement: "bottomLeft" });
      });
    } catch (error) {
      HLog(error);
    } finally {
    }
  };

  const getServiceId = (arrServ = []) => {
    try {
      let rs = [];

      for (const serv of arrServ) {
        if (!serv.children || !serv.children?.length) rs.push(serv.DV_ID);
        else {
          rs.push(...serv?.children?.map((c) => c?.DV_ID));
        }
      }
      return rs;
    } catch (error) {
      return [];
    }
  };

  const handleSubmitAndPrint = async () => {
    if (loading) return;
    new Promise((resolve, reject) => {
      handleSubmit(false)
        .then((rs) => {
          resolve(rs);
        })
        .then(() => handlePrint())
        .then(() => onAfterSubmit())
        .catch((err) => HLog(err));
    });
  };
  const handlePrint = async (LICH_KHAM_ID, list_DV_ID) => {
    if (loading) return;
    setLoading(true);
    try {
      const dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        LICH_KHAM_ID: appointmentDetail?.LICH_KHAM_ID,
        list_DV_ID: getServiceId(selectedService),
      };
      const api = apis.lay_data_in_phieu_tiep_don_v2;
      pdfRef.current.openModal(dataRequest, api, { isTiepDon: 1 });
    } catch (error) {
      HLog(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleOpenFastRegister = () => {
    fastRef.current.open();
  };

  const handleGetPackageWithPatientId = async (BENH_NHAN_ID) => {
    try {
      if (!BENH_NHAN_ID) throw new Error(i18n.t(languageKeys.vui_long_chon_benh_nhan));
      let body = {
        BENH_NHAN_ID,
        exclude_phong: 1,
        ARR_TRANG_THAI: ["ALL"],
      };

      let res = await common_post(apis.layDsGoiDvBenhNhan, body);
      if (res && res.status === "OK") {
        dispatch(
          PackageSliceAction.setPatientPackageList({
            BENH_NHAN_ID: body.BENH_NHAN_ID,
            dataSource: res.result,
            patient: patientPackageList.patient,
          })
        );
        formatAndSetOptions(res.result);
      } else {
        throw new Error(res);
      }
      return res;
    } catch (error) {
      genNotificationError(error.message);
    } finally {
    }
  };

  const handleReloadPack = async () => {
    setLoading(true);
    try {
      await handleGetPackageWithPatientId(patientPackageList.patient.BENH_NHAN_ID);
    } catch (error) {
      genNotificationError(i18n.t(languageKeys.common_co_loi_xay_ra));
    } finally {
      setLoading(false);
    }
  };

  // hàm xử lý phím tắt
  function keydownEvent(e) {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    if (e.ctrlKey && e.key === "i") {
      stopDefaultAction(e);
      handlePrint();
    }
    if (e.ctrlKey && e.key === "s") {
      stopDefaultAction(e);
      handleSubmit();
    }
    if (e.ctrlKey && e.key === "l") {
      stopDefaultAction(e);
      handleSubmitAndPrint();
    }
  }
  useEventListener("keydown", keydownEvent, window, open);

  return (
    <FormDrawer
      width={widthDrawerKeys.large}
      onCancel={() => handleClose()}
      visible={open}
      title={i18n.t(languageKeys.tao_phieu_chi_dinh_goi_dich_vu)}
      hiddenTopAction
    >
      <Layout style={{ height: "calc(100vh - 70px)" }}>
        <Layout.Sider theme="light" width={300}>
          <SiderIngredient
            loading={loading}
            expandedKeys={treeProps?.expandedKey}
            options={optionPackages}
            onSelectService={handleSelectService}
            checkedKeys={{
              checked: treeProps.checked,
              halfChecked: treeProps.halfCheckedKeys,
            }}
            disableKeys={treeProps?.disableKeys}
            onOpenFastRegister={handleOpenFastRegister}
          />
        </Layout.Sider>
        <Layout.Content style={{ background: "#fff", paddingTop: 12, paddingRight: 12 }}>
          <Layout>
            <Layout.Content>
              <Form form={form}>
                <Layout style={{ height: "calc(100vh - 160px)" }}>
                  <Layout.Content style={{ padding: 12 }}>
                    <Row style={{ paddingBottom: 12 }}>
                      <h2>{i18n.t(languageKeys.danh_sach_dich_vu_da_chon)}</h2>
                    </Row>
                    <Table
                      className={style['table-add-pack']}
                      columns={defaultColumns}
                      loading={loading}
                      dataSource={selectedService}
                      scroll={{ x: "max-content", y: "calc(100vh - 375px)" }}
                      expandRowByClick
                      expandIcon={({ expanded, onExpand, record }) =>
                        record.children ? (
                          expanded ? (
                            <CaretDownOutlined style={{ paddingRight: 6 }} onClick={(e) => onExpand(record, e)} />
                          ) : (
                            <CaretRightOutlined style={{ paddingRight: 6 }} onClick={(e) => onExpand(record, e)} />
                          )
                        ) : (
                          <></>
                        )
                      }
                    />
                  </Layout.Content>
                  {/* <Layout.Footer> */}
                  <div direction="vertical" size="small" style={{ width: "100%", padding: 12 }}>
                    <strong>{i18n.t(languageKeys.ghi_chu)}</strong>
                    <Form.Item name={"GHI_CHU"}>
                      <Input.TextArea
                        rows={2}
                        autoSize={false}
                        placeholder={i18n.t(languageKeys.nhap_loi_dan)}
                        style={{ background: "#fff", width: "100%" }}
                      />
                    </Form.Item>
                  </div>
                  {/* </Layout.Footer> */}
                </Layout>
              </Form>
            </Layout.Content>
          </Layout>

          <Layout.Footer style={{ background: "#fff" }}>
            <Row align={"middle"} justify="end" gutter={10}>
              <Col>
                <Button type="primary" ghost onClick={() => handleClose()}>
                  {i18n.t(languageKeys.common_Thoat)} (ESC)
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={handlePrint} loading={loading}>
                  {i18n.t(languageKeys.field_In_phieu)} (Ctrl + I)
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={handleSubmitAndPrint} loading={loading}>
                  {i18n.t(languageKeys.luu_va_in)} (Ctrl + L)
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={handleSubmit} loading={loading}>
                  {i18n.t(languageKeys.luu)} (Ctrl + S)
                </Button>
              </Col>
            </Row>
          </Layout.Footer>
        </Layout.Content>
      </Layout>
      <ModalActiveServicePack ref={activeServRef} handleAfterSubmit={handleAfterActive} />
      <ModalPDF ref={pdfRef} configCheck="NHIEU_PHIEU_CHIDINH_CLS" />
      <ModalDangKyNhanhGoi
        formBn={formBn}
        ref={fastRef}
        selectedPatient={patientPackageList?.patient}
        onSelectPatient={onSelectPatient}
        lichKhamInfo={appointmentDetail}
        onAfterSubmit={handleReloadPack}
      />
    </FormDrawer>
  );
});

export default ModalAddPackages;

const RoomSelect = React.memo(({ roomListId, roomId, record, setDataSource = () => {} }) => {
  const PHONG = useSelector((state) => state.data[indexedDbKeys.phong]);
  const [roomList, setRoomList] = React.useState([]);
  React.useEffect(() => {
    getRoomList();
  }, [roomId]);

  const getRoomList = () => {
    try {
      if (Array.isArray(roomListId) && roomListId.length) {
        let findRooms = getRoomWithServiceId(PHONG, roomListId);
        setRoomList(() => findRooms);
        // handleValueSelectAndDataSource(() => findRooms.find((r) => r.ID === roomId));
      }
    } catch (error) {
      HLog(error);
      HLog(error);
    }
  };

  const checkRoom = (id) => {
    try {
      return roomList.map((i) => i.ID).includes(id) ? id : undefined;
    } catch (error) {
      return undefined;
    }
  };
  return (
    <Select
      style={{ width: "100%" }}
      value={checkRoom(roomId)}
      dataSource={roomList}
      valueKey="ID"
      labelKey="TEN_KHOA_PHONG"
      onChange={(id_p) =>
        setDataSource((c) => {
          let findIndex = c.findIndex((i) => i.key === record.key);
          if (findIndex === -1) return c;
          let newDataSource = [...c];
          newDataSource[findIndex] = { ...newDataSource[findIndex], P_ID: id_p };
          return newDataSource;
        })
      }
    />
  );
});


const NoiThucHienXnSelect = React.memo(({ forceRender, roomListId, data, record, setDataSource = () => {}, noiThucHienList = [], dataSource }) => {

  return (
    <SelectAntd
      value={data?.DV_XN_NOI_THUC_HIEN_ID}
      style={{ width: "100%" }}
      options={noiThucHienList.map((obj) => ({ ...obj, label: obj.TEN_NOI_TH, value: obj.DV_XN_NOI_THUC_HIEN_ID }))}
      onChange={(value, opiton) => {

        let index
        let childIndex
        let newObj = { ...record, NOI_THUC_HIEN_XN: opiton }
        if (record.isChildren) {
          index = dataSource.findIndex((item) => item.key === record.keyParent);
          childIndex = dataSource[index]?.children?.findIndex((item) => item.key === record.key);
        } else {

          index = dataSource.findIndex((item) => item.key === record.key);
        }
        setDataSource((currentArr) => {
          let newArr = [...currentArr]
          if (record.isChildren) {
            let updatedChildren = [...newArr[index].children];
            updatedChildren[childIndex] = newObj;
            newArr[index] = { ...newArr[index], children: updatedChildren };
          } else {
            newArr[index] = newObj;
          }
          return newArr;
        })
      }

      }
    />
  );
});

const SelectChangeTarget = ({ targetId, record, setDataSource = () => {} }) => {
  const { customerTarget } = useSelector(getSharedData);
  const handleSelectTarget = (target_id) => {
    try {
      setDataSource((arr) => {
        let findIndex = arr.findIndex((i) => i.key === record?.key);
        if (findIndex === -1) return arr;
        let newDataSource = [...arr];
        newDataSource[findIndex] = { ...newDataSource[findIndex], DM_DOI_TUONG_ID: target_id };
        return newDataSource;
      });
    } catch (error) {
      HLog(error);
      return [];
    }
  };

  const checkExists = (id) => {
    try {
      return customerTarget.map((i) => i.ID).includes(id) ? id : undefined;
    } catch (error) {
      return undefined;
    }
  };

  return (
    <Select
      style={{ width: "100%" }}
      value={checkExists(targetId)}
      dataSource={customerTarget}
      labelKey={"TEN_DOI_TUONG"}
      valueKey={"ID"}
      onChange={(target_id) => handleSelectTarget(target_id)}
    />
  );
};

const appointmentColumnStandard = {
  BENH_NHAN_ID: "",
  DOI_TUONG_BENH_NHAN: "",
  TIEP_TAN_ID: "",
  MO_TA: "",
  GHI_CHU: "",
  LICH_KHAM_ID: "",
  // DM_DOI_TUONG_ID:"",
  DM_NGUON_KHACH_ID: "",
};

const checkNumber = (value) => {
  return Number.isNaN(+value) ? null : +value;
};

/**
 * @namespace DataLuuGoi
 * @typedef {Object} DataLuuGoi
 * @property {string} PHONG_THUC_HIEN_ID
 * @property {string} DV_ID
 * @property {number} SO_LUONG
 * @property {string} KHOA_ID
 * @property {string} PHONG_CHI_DINH
 * @property {number} UU_TIEN
 * @property {number} DON_GIA
 * @property {number} THANH_TIEN
 * @property {number} DON_GIA_PHAN_BO
 * @property {number} THANH_TIEN_PHAN_BO
 * @property {number} ID
 * @property {number} RLS_BN_GOI_DV_ID
 * @property {string} PHAN_LOAI
 * @property {string} BAC_SI_ID
 * @property {number} RLS_BN_GOI_DV_DVCON_ID
 * @property {string} GOI_ID
 * @property {number} DM_DOI_TUONG_ID
 */

/**
 * @namespace DvGoi
 * @typedef {Object} DvGoi
 *@property {number} PDT_ID
 *@property {string} PHONG_ID
 *@property {string} BAC_SI_ID
 *@property {string} LUOT_DIEU_TRI_ID
 *@property {number} SO_LUONG
 *@property {number} THOI_GIAN_TAO
 *@property {number} THOI_GIAN_SUA
 *@property {number} GIA_DICH_VU
 *@property {string} NGUOI_TAO
 *@property {string} NGUOI_SUA
 *@property {number} STT_VAO_KHAM
 *@property {string} DICH_VU_ID
 *@property {string} PHAN_LOAI
 *@property {string} NGAY
 *@property {string} GIO
 *@property {string} GIO_BAT_DAU
 *@property {string} GIO_KET_THUC
 *@property {object} LO_KHAM_ID
 *@property {string} TRANG_THAI
 *@property {object} HINH_THUC
 *@property {number} THANH_TOAN
 *@property {number} RLS_BN_GOI_DV_ID
 *@property {number} DM_DOI_TUONG_ID
 *@property {number} STT
 *@property {string} TEN_KHOA_PHONG
 *@property {string} KHOA_ID
 *@property {string} GIO_BAT_DAU_LAM_VIEC_CUA_PHONG
 *@property {string} GIO_KET_THUC_LAM_VIEC_CUA_PHONG
 *@property {string} USERNAME_NGUOI_TAO
 *@property {string} USERNAME_NGUOI_SUA
 *@property {string} BAC_SI
 *@property {number} ID_DV_IN_GOI
 *@property {string} DV_ID
 *@property {string} MA_DV
 *@property {string} TEN_DV
 *@property {number} PHONG_DIEU_TRI_ID
 *@property {object} RLS_BN_GOI_ID
 *@property {number} ID
 *@property {number} RLS_BN_GOI_DV_DVCON_ID
 *@property {number} DON_GIA_PHAN_BO
 *@property {number} DON_GIA
 *@property {number} THANH_TIEN
 *@property {number} THANH_TIEN_PHAN_BO
 *@property {object} GIA_BHYT
 *@property {object} GIA_DICHVU
 *@property {object} GIA_NUOC_NGOAI
 *@property {string} LICH_KHAM_ID
 *@property {number} NHOM_BHYT_ID
 *@property {string} MA_NHOM_CHI_CHI_BHYT
 *@property {string} TEN_NHOM_BHYT
 *@property {string} TRANG_THAI_THUC_HIEN
 *@property {string} TRANG_THAI_KET_QUA
 *@property {object} BARCODE_XET_NGHIEM
 *@property {object} BARCODE_QUAN_LY
 *@property {number} GOI_ID
 */
