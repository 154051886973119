import { createSlice } from "@reduxjs/toolkit";
import { ActionType, DataIndexOfServiceTable } from "pages/QuanLyGoiDichVu/DanhSachGoiDichVu/constant";
import { formatRateNumber, sumSpecificField } from "pages/QuanLyGoiDichVu/DanhSachGoiDichVu/helpers";

const initialState = {
  packetServiceList: [],
  serviceList: [],
  isLoadingGetList: false,
  dataSourceTableService: [],
  packetServiceDetail: {},
  filters: {},
  limit: 15,
  currentPage: 1,
  totalResult: 0,
  customerSourceList: [],
  commissionListLocal: {},
  isModalExemption: true
};

const PacketServiceListSlice = createSlice({
  name: "PacketServiceListSlice",
  initialState,
  reducers: {
    getPacketServiceList: () => {},
    getPacketServiceListWithoutFilter: () => {},
    getPacketServiceListSuccess: (state, { payload }) => {
      const { result, page, filters, limit, total } = payload;
      state.packetServiceList = result;
      state.currentPage = page;
      state.filters = filters;
      state.limit = limit;
      state.totalResult = total;
    },
    getChildrenService: () => {},
    getChildrenServiceSuccess: (state, { payload }) => {
      if (payload.length > 0) {
        const index = state.dataSourceTableService.findIndex((item) => item.key === payload[0].DV_CHA_ID);
        if (index !== -1) {
          const itemValue = state.dataSourceTableService[index].value;
          itemValue.children = payload?.map((i) => ({
            ...i,
            [DataIndexOfServiceTable.SL]: 1,
            [DataIndexOfServiceTable.THANH_TIEN]: i.DON_GIA,
            [DataIndexOfServiceTable.DON_GIA_PHAN_BO]: 0,
            [DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]: 0,
            [DataIndexOfServiceTable.TY_LE]: '0.0',
            [DataIndexOfServiceTable.NGUOI_CAP_NHAT]: itemValue?.NGUOI_CAP_NHAT,
            [DataIndexOfServiceTable.NGUOI_CAP_NHAT_ID]: itemValue?.NGUOI_CAP_NHAT_ID,
            [DataIndexOfServiceTable.GIO_CAP_NHAT]: itemValue?.GIO_CAP_NHAT,
            [DataIndexOfServiceTable.NGAY_CAP_NHAT]: itemValue?.NGAY_CAP_NHAT,
            isChildren: true,
          }));
          itemValue.isDV = true;
        }
      }
    },
    addPacketService: () => {},
    getDetailPacketService: () => {},
    getDetailPacketServiceSuccess: (state, { payload }) => {
      const { data, isCopy, currentDetail, NGUOI_CAP_NHAT, NHANSU_ID, GIO_CAP_NHAT, NGAY_CAP_NHAT, actionType, VAT_TU } = payload
      const { DS_DV, ...restPayload } = data
      state.dataSourceTableService = (DS_DV || [])?.map(((item, index) => {
        if (item?.DV_KEM_THEO?.length > 0) {
          return {
            DV_ID: item.DV_ID,
            key: item.DV_ID,
            value: {
              ...item,
              isDV: true,
              isChildren: false,
              [DataIndexOfServiceTable.TEN_DV]: item.TEN_DV,
              [DataIndexOfServiceTable.MA_DV]: item.MA_DV,
              [DataIndexOfServiceTable.SL]: item.SO_LUONG,
              [DataIndexOfServiceTable.DON_GIA]: item.DON_GIA,
              [DataIndexOfServiceTable.THANH_TIEN]: item.THANH_TIEN,
              [DataIndexOfServiceTable.DON_GIA_PHAN_BO]: item.DON_GIA_PHAN_BO,
              [DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]: item.THANH_TIEN_PHAN_BO,
              [DataIndexOfServiceTable.TY_LE]: item.TY_LE,
              [DataIndexOfServiceTable.NGUOI_CAP_NHAT_ID]: !isCopy ? item?.NGUOI_CAP_NHAT_ID : null,
              [DataIndexOfServiceTable.NGUOI_CAP_NHAT]: !isCopy ? item.NGUOI_CAP_NHAT : null,
              [DataIndexOfServiceTable.NGAY_CAP_NHAT]: !isCopy ? item.NGAY_CAP_NHAT : null,
              [DataIndexOfServiceTable.GIO_CAP_NHAT]: !isCopy ? item.GIO_CAP_NHAT : null,
              [DataIndexOfServiceTable.IS_CAP_NHAT]: isCopy ? true : false,
              children: item?.DV_KEM_THEO?.map(i => ({
                ...i,
                DV_CHA_ID: item.DV_ID,
                DV_ID: i.DV_ID,
                DV_KEM_THEO_ID: i.DV_ID,
                [DataIndexOfServiceTable.TEN_DV]: i.TEN_DV,
                [DataIndexOfServiceTable.MA_DV]: i.MA_DV,
                [DataIndexOfServiceTable.SL]: i.SO_LUONG,
                [DataIndexOfServiceTable.DON_GIA]: i.DON_GIA,
                [DataIndexOfServiceTable.THANH_TIEN]: i.THANH_TIEN,
                [DataIndexOfServiceTable.DON_GIA_PHAN_BO]: i.DON_GIA_PHAN_BO,
                [DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]: i.THANH_TIEN_PHAN_BO,
                [DataIndexOfServiceTable.TY_LE]: i.TY_LE,
                [DataIndexOfServiceTable.NGUOI_CAP_NHAT_ID]: !isCopy ? i?.NGUOI_CAP_NHAT_ID : null,
                [DataIndexOfServiceTable.NGUOI_CAP_NHAT]: !isCopy ? i.NGUOI_CAP_NHAT : null,
                [DataIndexOfServiceTable.NGAY_CAP_NHAT]: !isCopy ? i.NGAY_CAP_NHAT : null,
                [DataIndexOfServiceTable.GIO_CAP_NHAT]: !isCopy ? i.GIO_CAP_NHAT : null,
                [DataIndexOfServiceTable.IS_CAP_NHAT]: isCopy ? true : false,
                isChildren: true
              }))
            }
          }
        }
        return {
          DV_ID: item.DV_ID,
          key: item.DV_ID,
          value: {
            ...item,
            isChildren: false,
            [DataIndexOfServiceTable.TEN_DV]: item.TEN_DV,
            [DataIndexOfServiceTable.MA_DV]: item.MA_DV,
            [DataIndexOfServiceTable.SL]: item.SO_LUONG,
            [DataIndexOfServiceTable.DON_GIA]: item.DON_GIA,
            [DataIndexOfServiceTable.THANH_TIEN]: item.THANH_TIEN,
            [DataIndexOfServiceTable.DON_GIA_PHAN_BO]: item.DON_GIA_PHAN_BO,
            [DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]: item.THANH_TIEN_PHAN_BO,
            [DataIndexOfServiceTable.TY_LE]: item.TY_LE,
            [DataIndexOfServiceTable.NGUOI_CAP_NHAT_ID]: !isCopy ? item?.NGUOI_CAP_NHAT_ID : null,
            [DataIndexOfServiceTable.NGUOI_CAP_NHAT]: !isCopy ? item.NGUOI_CAP_NHAT : null,
            [DataIndexOfServiceTable.NGAY_CAP_NHAT]: !isCopy ? item.NGAY_CAP_NHAT : null,
            [DataIndexOfServiceTable.GIO_CAP_NHAT]: !isCopy ? item.GIO_CAP_NHAT : null,
            [DataIndexOfServiceTable.IS_CAP_NHAT]: isCopy ? true : false,
          }
        }
      }))


      if (isCopy) {
        state.packetServiceDetail = { ...currentDetail, VAT_TU }
      } else {
        state.packetServiceDetail = { ...restPayload, VAT_TU }
      }
    },
    editPacketService: () => {},
    addDatasourceTableService: (state, { payload }) => {

      const isExisted = state.dataSourceTableService.find((item) => item.key === payload.data.key);
      if (!isExisted) {
        const { value, NGUOI_CAP_NHAT, actionType, ...restPayload } = payload.data;
        state.dataSourceTableService = [
          ...state.dataSourceTableService,
          {
            ...restPayload,
            value: {
              ...value,
              [DataIndexOfServiceTable.SL]: 1,
              [DataIndexOfServiceTable.DON_GIA]: value[DataIndexOfServiceTable.DON_GIA] || 0,
              [DataIndexOfServiceTable.THANH_TIEN]: value[DataIndexOfServiceTable.DON_GIA] || 0,
              [DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]: 0,
              [DataIndexOfServiceTable.TY_LE]: '0.0',
              [DataIndexOfServiceTable.IS_CAP_NHAT]: true,
              isChildren: false,
            },
          },
        ];
      }
    },

    removeDatasourceTableService: (state, { payload }) => {
      const index = state.dataSourceTableService.findIndex(item => item.key === payload)
      if (index !== -1) {
        state.dataSourceTableService.splice(index, 1);
        state.dataSourceTableService = state.dataSourceTableService?.map(item => ({
          ...item,
          value: {
            ...item.value,
            [DataIndexOfServiceTable.TY_LE]: formatRateNumber((100 * item.value[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) / sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO)),
            children: item.value.children?.map(childItem => ({
              ...childItem,
              [DataIndexOfServiceTable.TY_LE]: formatRateNumber((
                (100 * (childItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) || 0) /
                sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO)
              ))
            }))
          }
        }))
      }
    },
    changeDatasourceTableService: (state, { payload }) => {
      const { value, key, inputChange, NGUOI_CAP_NHAT, NHANSU_ID, actionType } = payload;
      const indexItemChange = state.dataSourceTableService.findIndex(item => item.key === key)
      if (indexItemChange !== -1) {
        const formatedValue = value || 0;
        const valueItem = state.dataSourceTableService[indexItemChange].value
        if (inputChange === 'SL') {
          valueItem[DataIndexOfServiceTable.SL] = formatedValue
          valueItem[DataIndexOfServiceTable.THANH_TIEN] = formatedValue * valueItem[DataIndexOfServiceTable.DON_GIA]
          valueItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO] = formatedValue * (valueItem[DataIndexOfServiceTable.DON_GIA_PHAN_BO] || 0)
          valueItem[DataIndexOfServiceTable.TY_LE] = formatRateNumber((100 * valueItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) / sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO));
          valueItem[DataIndexOfServiceTable.IS_CAP_NHAT] = true;

          valueItem.children = valueItem.children?.map(childItem => ({
            ...childItem,
            [DataIndexOfServiceTable.IS_CAP_NHAT]: true
          }))
          state.dataSourceTableService = state.dataSourceTableService?.map(item => item.key === key ? ({
            ...item,
            [DataIndexOfServiceTable.TY_LE]: formatRateNumber((100 * item.value[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) / sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO)),
            children: item.children?.map(childItem => ({
              ...childItem,
              [DataIndexOfServiceTable.TY_LE]: formatRateNumber((100 * childItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) / sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO))
            }))
          }) : ({
            ...item,
            value: {
              ...item.value,
              [DataIndexOfServiceTable.TY_LE]: formatRateNumber((100 * item.value[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) / sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO)),
              children: item.value.children?.map(childItem => ({
                ...childItem,
                [DataIndexOfServiceTable.TY_LE]: formatRateNumber((100 * childItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) / sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO))
              }))
            }
          }))
          if (valueItem.isDV && valueItem?.children?.length) {
            valueItem.children = valueItem.children?.map(i => ({
              ...i,
              [DataIndexOfServiceTable.SL]: formatedValue,
              [DataIndexOfServiceTable.THANH_TIEN]: formatedValue * i.DON_GIA,
              [DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]: formatedValue * i.DON_GIA_PHAN_BO,
              [DataIndexOfServiceTable.TY_LE]: formatRateNumber((100 * formatedValue * i.DON_GIA_PHAN_BO) / sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO))
            }
            ))
            valueItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO] = valueItem?.children?.reduce((total, curr) => total + curr[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO], 0)
          }
        }
        if (inputChange === "DON_GIA_PHAN_BO") {
          const formatedValue = value || 0;
          valueItem[DataIndexOfServiceTable.DON_GIA_PHAN_BO] = formatedValue;
          valueItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO] = formatedValue * valueItem[DataIndexOfServiceTable.SL];
          valueItem[DataIndexOfServiceTable.TY_LE] = formatRateNumber((100 * valueItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) / sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO));
          if (actionType === ActionType.edit) {
            valueItem[DataIndexOfServiceTable.IS_CAP_NHAT] = true;
          }

          state.dataSourceTableService = state.dataSourceTableService?.map(item => item.key === key ? item : ({
            ...item,
            value: {
              ...item.value,
              [DataIndexOfServiceTable.TY_LE]: formatRateNumber((100 * item.value[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) / sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO)),
              children: item.value.children?.map(childItem => ({
                ...childItem,
                [DataIndexOfServiceTable.TY_LE]: formatRateNumber((
                  (100 * (childItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) || 0) /
                  sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO)
                ))
              }))
            }
          }))
          if (valueItem.isDV && valueItem?.children?.length) {
            const indexItem = valueItem.children.findIndex((item) => item.DV_ID === payload?.childrenKey);
            if (indexItem !== -1) {
              const childrenValueItem = valueItem.children[indexItem];
              childrenValueItem[DataIndexOfServiceTable.DON_GIA_PHAN_BO] = formatedValue;
              childrenValueItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO] = formatedValue * childrenValueItem[DataIndexOfServiceTable.SL];
              if (actionType === ActionType.edit) {
                childrenValueItem[DataIndexOfServiceTable.IS_CAP_NHAT] = true;
              }

              valueItem[DataIndexOfServiceTable.DON_GIA_PHAN_BO] = valueItem.children.reduce(
                (total, curr) => total + curr[DataIndexOfServiceTable.DON_GIA_PHAN_BO],
                0
              );
              valueItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO] = valueItem.children.reduce(
                (total, curr) => total + curr[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO],
                0
              );
              state.dataSourceTableService = state.dataSourceTableService?.map(item => item.key === key ? (
                {
                  ...item,
                  [DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]: valueItem.children.reduce(
                    (total, curr) => total + curr[DataIndexOfServiceTable.DON_GIA_PHAN_BO],
                    0
                  ) * valueItem[DataIndexOfServiceTable.SL],
                  value: {
                    ...item.value,
                    [DataIndexOfServiceTable.TY_LE]: formatRateNumber((100 * valueItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) / sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO)),
                    children: item.value.children?.map(childItem => ({
                      ...childItem,
                      [DataIndexOfServiceTable.TY_LE]: formatRateNumber((
                        (100 * (childItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) || 0) /
                        sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO)
                      ))
                    }))
                  }
                }
              ) : ({
                ...item,
                value: {
                  ...item.value,
                  [DataIndexOfServiceTable.TY_LE]: formatRateNumber((100 * item.value[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) / sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO))
                }
              }))
              childrenValueItem[DataIndexOfServiceTable.TY_LE] = formatRateNumber((
                (100 * childrenValueItem[DataIndexOfServiceTable.THANH_TIEN_PHAN_BO]) /
                sumSpecificField(state.dataSourceTableService, DataIndexOfServiceTable.THANH_TIEN_PHAN_BO)
              ));

            }
          }
        }
        if (inputChange === "NOI_THUC_HIEN") {
          valueItem[DataIndexOfServiceTable.TEN_KHOA_PHONG] = value.roomName;
          valueItem[DataIndexOfServiceTable.KHOA_PHONG_ID] = value.roomID;
          if (actionType === ActionType.edit) {
            valueItem[DataIndexOfServiceTable.IS_CAP_NHAT] = false;
            if (value?.isClicked && payload?.childrenKey) {
              valueItem[DataIndexOfServiceTable.IS_CAP_NHAT] = true;
            }
          }

          if (valueItem.isDV && valueItem?.children?.length) {
            valueItem[DataIndexOfServiceTable.TEN_KHOA_PHONG] = null;
            valueItem[DataIndexOfServiceTable.KHOA_PHONG_ID] = null;
            const indexItem = valueItem.children.findIndex((item) => item.DV_ID === payload?.childrenKey);
            if (indexItem !== -1) {
              const childrenValueItem = valueItem.children[indexItem];
              childrenValueItem[DataIndexOfServiceTable.TEN_KHOA_PHONG] = value.roomName;
              childrenValueItem[DataIndexOfServiceTable.KHOA_PHONG_ID] = value.roomID;
              if (actionType === ActionType.edit) {
                childrenValueItem[DataIndexOfServiceTable.IS_CAP_NHAT] = false;
              }

              if (value?.isClicked) {
                childrenValueItem[DataIndexOfServiceTable.IS_CAP_NHAT] = true;
              }
            }
          }
        }
      }
    },
    setDataSourceTableService: (state, { payload }) => {
      state.dataSourceTableService = payload;
    },
    resetDataSourceTableService: (state) => {
      state.dataSourceTableService = [];
      state.packetServiceDetail = {}
    },
    lockPacketService: () => {},
    deletePacketService: () => {},
    getCustomerSourceList: () => {},
    getCustomerSourceListSuccess: (state, { payload }) => {
      state.customerSourceList = payload
    },
    addCommissionListLocal: (state, { payload }) => {
      state.commissionListLocal[payload.name] = payload.value;
    },
    deleteCommissionLocal: (state, { payload }) => {
      const clone = state.commissionListLocal;
      delete clone[payload];
      state.commissionListLocal = clone;
    },
    updateIsModalExemption: (state, { payload }) => {
      state.isModalExemption = payload
    },
    clearCommissionLocal: (state, { payload }) => {
      state.commissionListLocal = {}
    }
  },
});

export const {
  getPacketServiceList,
  getPacketServiceListSuccess,
  getChildrenService,
  addDatasourceTableService,
  removeDatasourceTableService,
  changeDatasourceTableService,
  addPacketService,
  getChildrenServiceSuccess,
  resetDataSourceTableService,
  getDetailPacketService,
  getDetailPacketServiceSuccess,
  lockPacketService,
  deletePacketService,
  editPacketService,
  getPacketServiceListWithoutFilter,
  getCustomerSourceList,
  getCustomerSourceListSuccess,
  addCommissionListLocal,
  deleteCommissionLocal,
  updateIsModalExemption,
  clearCommissionLocal,
  setDataSourceTableService
} = PacketServiceListSlice.actions;
export const getServicePackages = (state) => state.servicePacket.PacketServiceList;
export default PacketServiceListSlice.reducer;
